.ag-theme-alpine {
    // Filters sidebar
    .ag-side-bar .ag-side-buttons .ag-side-button button {
        color: #3d2b52;
    }

    .ag-horizontal-left-spacer {
        overflow-x: hidden;
    }

    .ag-root-wrapper-body {
        ::-webkit-scrollbar {
            height: 6px;
            width: 6px;
        }

        .ag-body-horizontal-scroll {
            display: flex;
        }

        .ag-body-horizontal-scroll-container {
            height: 12px;
        }

        ::-webkit-scrollbar-thumb {
            background: #cdbbcb;
        }

        ::-webkit-scrollbar-thumb:hover {
            background: #ad9bab;
        }

        ::-webkit-scrollbar-track {
            background: #6c486a12;
        }
    }

    .tags {
        ::-webkit-scrollbar {
            height: 1px;
            width: 8px;
        }

        ::-webkit-scrollbar-thumb {
            background: #222;
            border-radius: 0;
        }

        ::-webkit-scrollbar-thumb:hover {
            background: #222;
        }

        ::-webkit-scrollbar-track {
            background: #fff;
        }
    }

    .ag-pinned-left-cols-container {
        border-right: 1px solid #d9d8d8;
    }

    &.calendar {
        .ag-ltr {
            .ag-center-cols-container {
                .ag-cell {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        .calendarDayHeader {
            padding: 0;
        }
    }

    &.settings {
        .ag-ltr {
            .ag-center-cols-container {
                .ag-cell {
                    padding-left: 0;
                    padding-right: 8px;
                    overflow: visible;
                }
            }
        }

        .ag-header-cell {
            padding-left: 8px;
            padding-right: 5px;
        }
    }

    .ag-cell {
        -webkit-font-smoothing: antialiased;

        // This is to only target calendar day cell so the ends of booking can overflow to the next day.
        &[col-id^='rate_price'] {
            overflow: visible;
        }
    }

    .ag-cell-range-selected {
        border: 0 !important;
        border-right: 1px solid rgb(160 137 158 / 10%) !important;
    }

    .ag-checkbox-input-wrapper {
        input {
            cursor: pointer;
        }
    }

    .ag-header-cell-comp-wrapper {
        > div {
            width: 100%;
        }
    }

    .ag-column-first {
        .ag-header-cell-comp-wrapper {
            z-index: 2;
        }
    }
}

.ag-header-group-cell-label-custom {
    position: relative;
}

.customHeaderMenuButton {
    width: 16px;
    height: 16px;
    background: transparent url('/icons/dots-three-outline-vertical.svg') no-repeat;
    background-position: center center;
    background-size: 12px 12px;
    cursor: pointer;
    flex-shrink: 0;
    opacity: 0.5;

    &:hover {
        opacity: 0.7;
    }
}

.customSortDownLabel,
.customSortUpLabel {
    width: 12px;
    height: 16px;
    flex-shrink: 0;
    cursor: pointer;
    opacity: 0.3;

    &:hover {
        opacity: 0.7;
    }

    &.active {
        opacity: 1;
    }
}

.customSortDownLabel {
    background: transparent url('/icons/sort-down-arrow.svg') no-repeat;
    background-position: center center;
    background-size: 12px 12px;
}

.customSortUpLabel {
    background: transparent url('/icons/sort-up-arrow.svg') no-repeat;
    background-position: center center;
    background-size: 12px 12px;
}

.customHeaderLabel {
    font-size: 12px;
}

.customSortRemoveLabel {
    cursor: pointer;
    width: 16px;
    height: 16px;
    background: transparent url('/icons/x.svg') no-repeat;
    background-position: center center;
    background-size: 12px 12px;
    flex-shrink: 0;
    opacity: 1;
    display: none;

    &.active {
        display: block;
    }
}

.ag-cell-label-container {
    padding-top: 2px;
    padding-bottom: 2px;
}

.ag-cell-label-container-child {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
}

.customHeaderSettings {
    .ag-header-group-cell-label {
        margin-bottom: 1px;
    }
}

.customHeaderEditButtonContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;

    > div {
        display: flex;
    }
}

.scrollbar {
    ::-webkit-scrollbar {
        height: 4px;
        width: 8px;
    }

    ::-webkit-scrollbar-thumb {
        background: #222;
        border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #222;
    }

    ::-webkit-scrollbar-track {
        background: #f9f9f9;
    }
}
