/**
 * Copied and modified from react-geosuggest.
 * The geosuggest module
 */

@use '../../global/themes/phoenix/constants.css.scss';

.geosuggest {
    font-size: 18px;
    position: relative;
    margin: 1em auto 0;
    text-align: left;
}

.geosuggest__input {
    width: 100%;
    font-size: 24px;
    border: 2px solid transparent;
    border-radius: 2px;
    box-shadow: 0 1px 4px 1px rgb(72 76 87 / 35%);
    padding: 28px 100px 28px 90px;
    transition:
        border 0.2s,
        box-shadow 0.2s;
    transition:
        border 0.2s,
        box-shadow 0.2s;

    @media (max-width: constants.$response-width-break-point-medium) {
        font-size: 18px;
    }

    @media (max-width: constants.$response-width-break-point-small) {
        padding: 20px 100px 20px 50px;
        font-size: 16px;
    }
}

.geosuggest__input:focus {
    border-color: #267dc0;
    box-shadow: 0 0 0 transparent;
}

.geosuggest__suggests {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 25em;
    padding: 0;
    margin-top: -1px;
    background: #fff;
    border: 2px solid #267dc0;
    border-top-width: 0;
    overflow: hidden auto;
    list-style: none;
    z-index: 5;
    transition:
        max-height 0.2s,
        border 0.2s;
    transition:
        max-height 0.2s,
        border 0.2s;
}

.geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
}

/**
 * A geosuggest item
 */
.geosuggest__item {
    font-size: 18px;
    padding: 0.5em 0.65em;
    cursor: pointer;
    display: block;
}

.geosuggest__item:hover,
.geosuggest__item:focus {
    background: #f5f5f5;
}

.geosuggest__item--active {
    background: #b81eda;
    color: #fff;
}

.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
    background: #ccc;
}
