.mapboxgl-map {
    font: 12px / 16px var(--font-inter) !important;
}

.mapboxgl-popup-content,
.mapboxgl-popup {
    width: 100%;
}

/** Mapbox GL Additions **/

.mapbox-gl-draw_ctrl-bottom-left,
.mapbox-gl-draw_ctrl-top-left {
    border-radius: 0 4px 4px 0;
    margin-left: 0;
}

.mapbox-gl-draw_ctrl-bottom-right,
.mapbox-gl-draw_ctrl-top-right {
    border-radius: 4px 0 0 4px;
    margin-right: 0;
}

.mapbox-gl-draw_ctrl-draw {
    background-color: rgb(0 0 0 / 75%);
    border-color: rgb(0 0 0 / 90%);
}

.mapbox-gl-draw_ctrl-draw > button {
    border-color: rgb(0 0 0 / 90%);
    color: hsl(0deg 0% 100% / 50%);
    height: 30px;
    width: 30px;
}

.mapbox-gl-draw_ctrl-draw > button:hover {
    background-color: rgb(0 0 0 / 85%);
    color: hsl(0deg 0% 100% / 75%);
}

.mapbox-gl-draw_ctrl-draw > button.active,
.mapbox-gl-draw_ctrl-draw > button.active:hover {
    background-color: rgb(206 44 163 / 5%);
    border-width: 1px;
    border-color: #c50fc3;
    color: #c50fc3;
}

.mapbox-gl-draw_ctrl-draw-btn {
    background-position: 50%;
    background-repeat: no-repeat;
}

.mapbox-gl-draw_uncombine {
    width: 16px;
    height: 16px;
    background: transparent url('/icons/subtract-square-light.svg') no-repeat;
    background-position: center center;
    background-size: 16px 16px;
}

.mapbox-gl-draw_combine {
    width: 16px;
    height: 16px;
    background: transparent url('/icons/unite-square-light.svg') no-repeat;
    background-position: center center;
    background-size: 16px 16px;
}

.mapboxgl-map.mode-direct_select.feature-feature.mouse-move .mapboxgl-canvas-container.mapboxgl-interactive,
.mapboxgl-map.mode-direct_select.feature-vertex.mouse-move .mapboxgl-canvas-container.mapboxgl-interactive,
.mapboxgl-map.mouse-move .mapboxgl-canvas-container.mapboxgl-interactive {
    cursor: move;
}

.mapboxgl-map.mode-static.mouse-pointer .mapboxgl-canvas-container.mapboxgl-interactive,
.mapboxgl-map.mouse-move.mode-direct_select .mapboxgl-canvas-container.mapboxgl-interactive {
    cursor: grab;
    cursor: grab;
}

.mapboxgl-map.mode-direct_select.feature-midpoint.mouse-pointer .mapboxgl-canvas-container.mapboxgl-interactive {
    cursor: cell;
}

.mapbox-gl-draw_boxselect {
    background: rgb(0 0 0 / 10%);
    border: 2px dotted #fff;
    height: 0;
    left: 0;
    opacity: 0.5;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 0;
}

/** Mapbox gl custom styles **/
.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon.active {
    background-color: rgb(206 44 163 / 30%);
    border: 1px solid;
    border-color: #c50fc3;
    color: #c50fc3 !important;
    background: rgb(206 44 163 / 10%) url('/icons/map-draw-polygon-active.svg') no-repeat;
    background-position: center center;
    background-size: 16px 16px;
}

/** Mapbox gl custom styles **/
.mode-draw_rectangle .mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_rectangle {
    background-color: rgb(206 44 163 / 30%);
    border: 1px solid;
    border-color: #c50fc3;
    color: #c50fc3 !important;
    background: rgb(206 44 163 / 10%) url('/icons/map-draw-rectangle-active.svg') no-repeat;
    background-position: center center;
    background-size: 16px 16px;
}

.mode-simple_select .mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_edit {
    background-color: rgb(206 44 163 / 30%);
    border: 1px solid;
    border-color: #c50fc3;
    color: #c50fc3 !important;
    background: rgb(206 44 163 / 10%) url('/icons/map-draw-edit-active.svg') no-repeat;
    background-position: center center;
    background-size: 16px 16px;
}

.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_line.active {
    background-color: rgb(0 0 0 / 7%);
}

.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_trash.active {
    background-color: rgb(0 0 0 / 7%);
}

.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_combine.active {
    background-color: rgb(0 0 0 / 7%);
}

.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_uncombine.active {
    background-color: rgb(0 0 0 / 7%);
}

.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_point.active {
    background-color: rgb(0 0 0 / 7%);
}

.mapboxgl-ctrl-group:not(:empty) {
    box-shadow: 0 0 0 1px rgb(0 0 0 / 3%) !important;

    button {
        background-color: white;

        &:last-child {
            border-radius: 4px;
        }
    }
}

.mapboxgl-ctrl-group:has(.control-no-background) {
    background: transparent;
    box-shadow: none !important;
}

.mapboxgl-popup-content {
    border-radius: 8px !important;
    background-color: transparent;
    box-shadow: none !important;
    padding: 0 !important;
}

.mapboxgl-ctrl-legend summary {
    font-weight: 500 !important;
    text-transform: capitalize !important;
    font-size: 12px !important;
}

.mapboxgl-ctrl-legend .list {
    li {
        &::before {
            margin-right: 4px !important;
        }

        padding: 1.5px 0 !important;
        display: flex !important;
        align-items: center !important;
        font-size: var(--map-legend-label-size) !important;
        color: var(--map-legend-label-color) !important;
        text-transform: capitalize !important;
    }
}

:root {
    --map-legend-minwidth: 200px !important;
    --map-legend-maxwidth: 200px !important;
    --map-legend-font: 12px / 1 'Inter', sans-serif !important;
    --map-legend-border: 1px solid rgb(0 0 0 / 5%) !important;
    --map-legend-shadow: 0 0 0 1px rgba(0 0 0 / 5%) !important;
    --map-legend-label-size: 12px !important;
    --map-legend-label-color: #777 !important;
    --map-legend-margin: 0.5rem 0.75rem !important;
    --map-legend-title-size: 12px !important;
}

.mapboxgl-ctrl-legend .list li {
    padding: 1px 0 4px !important;
}

.mapboxgl-ctrl-legend .list {
    margin: 0.2rem 0.75rem !important;
}

.mapboxgl-ctrl-legend .list li .icon {
    display: inline-block;
    margin-right: 4px;
}

.mapboxgl-ctrl-legend .panes {
    width: 100%;
}

.mapbox-gl-draw_polygon {
    background: transparent url('/icons/map-draw-polygon.svg') no-repeat;
    background-position: center center;
    background-size: 16px 16px;
}

.mapbox-gl-draw_rectangle {
    width: 16px;
    height: 16px;
    background: transparent url('/icons/map-draw-rectangle.svg') no-repeat;
    background-position: center center;
    background-size: 16px 16px;
}

.mapbox-gl-draw_point {
    width: 16px;
    height: 16px;
    background: transparent url('/icons/map-pin.svg') no-repeat;
    background-position: center center;
    background-size: 16px 16px;
}

.mapbox-gl-draw_line {
    width: 16px;
    height: 16px;
    background: transparent url('/icons/map-draw-line.svg') no-repeat;
    background-position: center center;
    background-size: 16px 16px;
}

.mapbox-gl-draw_circle {
    width: 16px;
    height: 16px;
    background: transparent url('/icons/map-draw-circle.svg') no-repeat;
    background-position: center center;
    background-size: 16px 16px;
}

.mapbox-gl-draw_edit {
    width: 16px;
    height: 16px;
    background: transparent url('/icons/map-draw-edit.svg') no-repeat;
    background-position: center center;
    background-size: 16px 16px;
}

.mapbox-gl-center_boundary {
    width: 17px;
    height: 17px;
    background: transparent url('/icons/center-boundary.svg') no-repeat;
    background-position: center center;
    background-size: 17px 17px;
}

.mapbox-gl-draw_store {
    width: 16px;
    height: 16px;
    background: transparent url('/icons/map-draw-save-boundary.svg') no-repeat;
    background-position: center center;
    background-size: 16px 16px;
}

.mapbox-gl-draw_boundary-selection {
    width: 16px;
    height: 16px;
    background: transparent url('/icons/map-select-boundaries.svg') no-repeat;
    background-position: center center;
    background-size: 16px 16px;
}

.edit-control.hidden {
    .mapbox-gl-draw_edit {
        display: none;
    }
}

.mapboxgl-ctrl-group button + button {
    border-top: 1px solid #eee !important;
}

.mapboxgl-ctrl.save-cancel-control {
    border-radius: 2px;
    padding: 4px 8px 8px;
    width: 150px;
    margin-top: 4px;
    margin-right: 4px;
    float: right;
    clear: both;
    cursor: pointer;
    pointer-events: auto;
}

.mapboxgl-ctrl.save-cancel-control .title {
    font-family: Inter;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    margin-bottom: 4px;
}

.mapboxgl-ctrl.save-cancel-control button {
    font-family: Inter;
    font-weight: 500;
    font-size: 12px;
    flex-shrink: 0;
    flex: 1;
    height: auto;
    border-radius: 2px !important;
    padding: 2px 4px;
    display: block;
}

.mapboxgl-ctrl.save-cancel-control .flex {
    width: 100%;
    display: flex;
    gap: 5px;
}

.mapboxgl-ctrl .mapboxgl-draw-actions-btn_save {
    background-color: #444;
    color: white;

    &:hover {
        background-color: #666 !important;
    }
}

.mapboxgl-ctrl .mapboxgl-draw-actions-btn_cancel {
    background-color: #ecebeb;
    color: #444;
}

.mapboxgl-ctrl-group button {
    position: relative;
    overflow: visible !important;
}

.mapbox-gl-market_control {
    width: 16px;
    border-radius: 4px;
    height: 16px;
    background: transparent url('/icons/market-boundary.svg') no-repeat;
    background-position: center center;
    background-size: 16px 16px;
}

.mapbox-gl-done-editing {
    width: 16px;
    border-radius: 4px;
    height: 16px;
    background: transparent url('/icons/map-done-editing.svg') no-repeat;
    background-position: center center;
    background-size: 16px 16px;
}

.mapbox-gl-draw_trash {
    width: 16px;
    border-radius: 4px;
    height: 16px;
    background: transparent url('/icons/map-draw-delete.svg') no-repeat;
    background-position: center center;
    background-size: 16px 16px;
}

.mapboxgl-ctrl-geolocate > .mapboxgl-ctrl-icon,
.mapbox-gl-geolocate {
    width: 18px;
    border-radius: 4px;
    height: 18px;
    background-image: url('/icons/map-draw-geolocate.svg') !important;
    background-position: center center;
    background-size: 18px 18px;
}

.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
    width: 16px;
    height: 16px;
    background-image: url('/icons/mapboxgl-ctrl-zoom-in.svg') !important;
    background-position: center center;
    background-size: 16px 16px;
}

.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
    border-radius: 4px;
    width: 16px;
    height: 16px;
    background-image: url('/icons/mapboxgl-ctrl-zoom-out.svg') !important;
    background-position: center center;
    background-size: 16px 16px;
}

.mapbox-gl-draw_import {
    width: 16px;
    border-radius: 4px;
    height: 16px;
    background: transparent url('/icons/map-draw-add-boundary.svg') no-repeat;
    background-position: center center;
    background-size: 16px 16px;
}

.mapbox-gl-secondary-button {
    background-color: #f1f1f1 !important;
    height: 30px !important;
}

.mapboxgl-ctrl-top-left {
    display: flex;
    align-items: center;
    gap: 4px;
    top: 8px !important;
    left: 8px !important;
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
    display: none !important;
}

.mapboxgl-ctrl-top-left .mapboxgl-ctrl {
    margin: 2px !important;
}

.mapboxgl-popup-tip {
    display: none !important;
}

.__dbk__child-wrapper {
    display: flex;
}

@keyframes widget-bar {
    0% {
        transform: translateX(-100%);
    }

    50% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(100%);
    }
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
    position: relative;
    box-shadow: none;
    border-radius: 0;
    border-right-width: 1px;
    border-color: hsl(0deg 0% 90% / 75%);
    font-size: 13px;
    z-index: 3;

    svg {
        display: none;
    }

    &::before {
        content: '';
        position: absolute;
        left: 10px;
        top: 9px;
        width: 15px;
        height: 15px;
        background: transparent url('/icons/mapboxgl-ctrl-geocoder.svg') no-repeat;
        background-position: center center;
        background-size: 15px 15px;
    }

    > input {
        height: 33px;
        border-radius: 0;
        padding: 6px 12px 6px 36px;
    }
}

.mapbox-gl-legend-button {
    width: 24px !important;
    min-width: auto !important;
}
