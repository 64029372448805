/**
 * NOTE: Turbo has an issue parsing the first class in this file.
 * This is a workaround to ensure the other styles are applied.
 */
.reactPaginate {
    display: block;
}

.reactPaginate ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px;
    font-size: 14px;
    font-weight: 500;
}

.reactPaginate li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;

    &.previous,
    &.next {
        width: auto;
        margin: 0 5px;
    }

    &.disabled a {
        opacity: 0;
        cursor: default;

        &:hover {
            text-decoration: none;
        }
    }

    &.active {
        border: solid 1px var(--chakra-colors-purple-400);

        a {
            color: var(--chakra-colors-purple-400);
        }
    }
}
