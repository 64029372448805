@use 'sass:meta';
@use 'global/modules/pagination.css';
@use 'global/modules/geosuggest.css.scss';
@use 'global/modules/mapbox.css.scss';
@use 'grid/ag-grid-modifications.css.scss';

html {
    height: 100%;
    min-height: 100%;
}

body {
    height: 100%;
    min-height: 100%;
}

body > #__next {
    height: 100% !important;
}

.scroll-update::-webkit-scrollbar {
    width: 8px;
}

.scroll-update::-webkit-scrollbar-track {
    box-shadow: none;
    background: #f7f7f7;
}

.scroll-update::-webkit-scrollbar-thumb {
    background-color: #c8c9ca;
}

.horizontal-scrollbar::-webkit-scrollbar {
    display: none;
}

.horizontal-scrollbar::-webkit-scrollbar-track {
    box-shadow: none;
    background: #f4f4f5;
}

.horizontal-scrollbar::-webkit-scrollbar-thumb {
    background-color: #343234;
}

.overflow-shadow {
    background:
        /* Shadows */
        linear-gradient(to right, white, white),
        linear-gradient(to right, white, white),
        /* Shadow covers */ linear-gradient(to right, rgb(0 0 0 / 7%), rgb(255 255 255 / 0%)),
        linear-gradient(to left, rgb(0 0 0 / 7%), rgb(255 255 255 / 0%));
    background-position:
        left center,
        right center,
        left center,
        right center;
    background-repeat: no-repeat;
    background-color: white;
    background-size:
        20px 100%,
        20px 100%,
        10px 100%,
        10px 100%;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
}

[data-testid='DatepickerInput'] {
    border-radius: 4px;

    &::placeholder {
        font-weight: 400 !important;
    }
}

.highcharts-tooltip > span {
    padding-top: 8px;
    min-width: 145px;
    max-width: 350px;
    white-space: normal;
}

.highcharts-tooltip-container .highcharts-tooltip .textContainer {
    word-break: break-all;
}

.WTF {
    width: 300px !important;
    display: block !important;
}

.tooltip-marker {
    display: flex;
    width: 100%;
    padding: 0;
}

.scrollbar-magic * {
    ::-webkit-scrollbar {
        height: 2px;
        width: 8px;
    }

    ::-webkit-scrollbar-thumb {
        background: #c8c9ca;
        border-radius: 0;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #c8c9ca;
    }

    ::-webkit-scrollbar-track {
        background: #f7f7f7;
    }
}

.highcharts-container {
    overflow: initial !important;
}

.echarts-for-react {
    max-height: 160px !important;

    canvas {
        overflow: visible !important;
    }
}

.ag-header-cell:has(.select-all),
.ag-header-cell-comp-wrapper:has(.select-all) {
    overflow: visible;
}

.react-grid-item > .react-resizable-handle {
    position: absolute;
    display: none;
    width: 20px;
    height: 20px;
    bottom: 8px;
    right: 8px;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
    opacity: 0.4;
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
}

.edit-mode .react-grid-item:not(.not-resizable) > .react-resizable-handle {
    display: block;
}

.RightSlot {
    margin-left: auto;
}

.tiptap {
    height: 100%;

    &:focus {
        outline: none;
    }

    &:first-child {
        margin-top: 0;
    }

    /* List styles */
    ul,
    ol {
        padding: 0 1rem;
        margin: 1.25rem 1rem 1.25rem 0.4rem;

        li p {
            margin-top: 0.25em;
            margin-bottom: 0.25em;
        }
    }

    /* Heading styles */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
        margin-top: 0.5rem;
        text-wrap: pretty;
    }

    h1,
    h2 {
        margin-top: 3.5rem;
        margin-bottom: 1.5rem;
    }

    h1 {
        font-size: 1.4rem;
    }

    h2 {
        font-size: 1.2rem;
    }

    h3 {
        font-size: 1.1rem;
    }

    h4,
    h5,
    h6 {
        font-size: 1rem;
    }

    /* Code and preformatted text styles */
    code {
        background-color: var(--purple-light);
        border-radius: 0.4rem;
        color: var(--black);
        font-size: 0.85rem;
        padding: 0.25em 0.3em;
    }

    pre {
        background: var(--black);
        border-radius: 0.5rem;
        color: var(--white);
        font-family: JetBrainsMono, monospace;
        margin: 1.5rem 0;
        padding: 0.75rem 1rem;

        code {
            background: none;
            color: inherit;
            font-size: 0.8rem;
            padding: 0;
        }
    }
}

.react-grid-item.react-grid-placeholder {
    background: rgb(251 0 255 / 6.4%);
}
